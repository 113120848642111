import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className="message_banner"> 👋 My Links tree </div>
      <div className='container'>
      <div className="profile">
            
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNXhL16R_pj1tcGXgaT-low6HQetyNqfLYu9IC-qkN8Odusd5olew3unS6s-rsKDZf3Fcya2v2xal8gfNAec-wgEr38ULhT0UGqroSc1dUgHDKbUaZ_fkL9laWxBf5C2l0obbFyWvvcqfJPxzq0fcdsIw=w400-h400-s-no-gm" alt="React Image" />
            <h1>Mauricio Alcala</h1>
            <p>Software Engineer</p>
        </div>

        <div className="links">
            <p><a href="https://twitter.com/intmau" class="link-button">My X Account</a></p>
            <p><a href="https://www.linkedin.com/in/mauricioalcala" class="link-button">LinkedIn</a></p>
            <p><a href="https://github.com/maualkla" class="link-button">GitHub</a></p>
        </div>
      </div>
      <div className="message_banner" id="footer"></div>
    </div>
  );
}



// Triggers for _menu options
// _home
function _homeClick(){ 
  console.log(" _home click ")
  hideAll();
  document.getElementById('_home_sect').classList.remove("_hidden");
}

// _prof
function _profClick(){ 
  console.log(" _prof click ")
  hideAll();
  document.getElementById('_prof_sect').classList.remove("_hidden");
}

// _educ
function _educClick(){ 
  console.log(" _educ click ")
  hideAll();
  document.getElementById('_educ_sect').classList.remove("_hidden");
}

// _proj
function _projClick(){ 
  console.log(" _proj click ")
  hideAll();
  document.getElementById('_proj_sect').classList.remove("_hidden");
}

// _cont
function _contClick(){ 
  console.log(" _cont click ")
  hideAll();
  document.getElementById('_cont_sect').classList.remove("_hidden");
}

// _addi
function _addiClick(){ 
  console.log(" _addi click ")
  hideAll();
  document.getElementById('_addi_sect').classList.remove("_hidden");
}

function hideAll(){
  document.getElementById('_home_sect').classList.add("_hidden");
  document.getElementById('_prof_sect').classList.add("_hidden");
  document.getElementById('_educ_sect').classList.add("_hidden");
  document.getElementById('_proj_sect').classList.add("_hidden");
  document.getElementById('_cont_sect').classList.add("_hidden");
  document.getElementById('_addi_sect').classList.add("_hidden");
}

export default App;
